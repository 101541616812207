
import React from 'react'
import { siteMetadata } from '../../gatsby-config'
import Layout from '../components/layout/layout'
import Meta from '../components/meta/meta'
import '../css/colophon.css'

const Profile: React.FC<Props> = ({ location, data }: Props) => {


  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Profile" />
      <div>
      <div className="container-fluid content" id="content">  
   <div className="row">
     <div className="col-sm-12 col-md-12 col-lg-6">
       <h2>Hi, I'm <span id="num">█████</span></h2>
     </div>
   </div>
   <div className="row">
     <div className="col-sm-12 col-md-12 col-lg-6">
       <h2>Born in the <span id="num">U.K.</span></h2>
     </div>
   </div>
   <div className="row">
     <div className="col-sm-12 col-md-12 col-lg-6">
       <h2 id="odd">Father & Husband</h2>
     </div>
   </div>
   <div className="row">
     <div className="col-sm-12">
       <h2 id="last">Developer, Senior Consultant, Designer, Engineer, Photographer</h2>
     </div>
   </div>




   <h2 className="stripe-4">&nbsp;</h2>


   <div className="row" id="contentRow">
     <div className="col-sm-12 col-md-12 col-lg-4" id="spacer">
       <ul className="list-group">
         <li className="list-group-item" id="lgiTitle">Languages / Frameworks / Libraries</li>
         <li className="list-group-item">HTML5 / CSS / SCSS</li>
         <li className="list-group-item">JS / AngularJS / REACT</li>
         <li className="list-group-item">Powershell / Python / GO</li>
         <li className="list-group-item">SQL / GraphQL</li>

       </ul>
     </div>
     <div className="col-sm-12 col-md-12 col-lg-4" id="spacer">
       <ul className="list-group">
         <li className="list-group-item" id="lgiTitle">Professional Skills</li>
         <li className="list-group-item">ServiceNow Developer / Architect</li>
         <li className="list-group-item">Automation / CI / CD / Integrations</li>
         <li className="list-group-item">ETL / Data Warehousing / Data Science </li>
         <li className="list-group-item">Graphic Design & Video Editing</li>

       </ul>
     </div>
     <div className="col-sm-12 col-md-12 col-lg-4" id="spacer">
       <ul className="list-group">
         <li className="list-group-item" id="lgiTitle">Platform Development Experience</li>
         <li className="list-group-item">ServiceNow Certifed Admin & Developer</li>
         <li className="list-group-item">Jenkins / Mulesoft</li>
         <li className="list-group-item">SalesForce / Desk.com</li>
         <li className="list-group-item">Workday</li>
       </ul>
     </div>
   </div>
   <h2 className="stripe-4">&nbsp;</h2>
   </div>


      </div>
    </Layout>
  )
}

export default Profile

